//-------------------------------------------------------//
//------ Import - Fonts
//-------------------------------------------------------//
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700,400italic);

@import url(https://fonts.googleapis.com/css?family=Merriweather:700italic);

//-------------------------------------------------------//
//------ Import - Bootstrap( Variables, Mixins)
//-------------------------------------------------------//
@import "../frameworks/bootstrap-4.0.0-alpha.3/scss/variables";
@import "../frameworks/bootstrap-4.0.0-alpha.3/scss/mixins";

//-------------------------------------------------------//
//------ General
//-------------------------------------------------------//
$f-merri: "Merriweather", serif;

$c-blue: #2facdf;

html {
    width: 100%;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
}

body {
    // font-family: $f-roboto;
    font-size: 1rem;
    color: #606060;
}

.container-fluid {
    max-width: 1230px;
    padding-left: 15px;
    padding-right: 15px;
}

.img-responsive {
    display: inline-block;
    width: 100%;
    height: 100%;
}

ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

a:hover, a:visited, a:focus, a:active {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}

.img-responsive {
    width: 100%;
    display: inline-block;
}

.ab-center {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.btn {
    display: inline-block;
    min-width: 160px;
    font-size: 20px;
    &.btn-first {
        color: $c-blue;
    }
    &.btn-third {
        position: relative;
        border-width: 2px;
        height: 44px;
        line-height: 40px;
        font-size: 20px;
        text-align: left;
        font-weight: 700;
        &:hover {
            border: 2px solid #fff;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: 100;
            width: 14px;
            height: 23px;
            top: 0;
            left: auto;
            right: 5px;
            bottom: 0;
            margin: auto;
            background-image: url("../images/ico-arrow-white-right.png");
        }
    }
}

//resets bootstrap
.navbar {
    padding: 0;
    background-color: #fff;
}

h1 {}

h2 {}

h3 {}

h4 {}

h5 {
    font-size: 20px;
    font-weight: 700;
}

h6 {}

//-------------------------------------------------------//
//------ Modal
//-------------------------------------------------------//
#contactModal {
    font-family: 'Lato', sans-serif;
    color: #606060;
    .modal-dialog .modal-content {
        position: relative;
        padding: 40px 40px 40px 220px;
        @include media-breakpoint-only(md) {
            padding-left: 320px;
        }

        @include media-breakpoint-only(sm) {
            padding-left: 220px;
        }

        @include media-breakpoint-down(xsm) {
            padding: 20px 20px 160px;
            form {
                label {
                    font-size: 16px;
                }
            }
        }
    }
    .modal-header {
        h6 {
            font-size: 18px;
            font-weight: 700;
        }
    }
    .modal-title {
        color: #cf3a99;
        font-size: 40px;
        font-family: $f-merri;
        font-weight: 700;
        font-style: italic;
        @include media-breakpoint-down(xsm) {
            font-size: 32px;
        }
    }
    form {
        font-weight: 700;
        label {
            font-size: 20px;
        }
        input[type='text'], input[type='email'], textarea {
            border: 1px solid #dfdfdf;
            height: 48px;
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            padding: 0 15px;
            font-size: 14px;
            border-radius: 2px;
            @include media-breakpoint-down(md) {
                // width: 300px;
            }

            @include media-breakpoint-down(xxs) {
                // width: 100%;
            }
        }
        textarea {
            min-height: 70px;
            height: auto;
            padding: 15px;
            max-height: 120px;
        }
        input[type='submit'] {
            min-width: 120px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            font-weight: 700;
            margin-top: 20px;
        }
    }
    .aside-info {
        color: #fff;
        width: 300px;
        padding: 30px;
        height: 500px;
        position: absolute;
        left: -120px;
        top: 70px;
        font-weight: 700;
        background: #fff url("../images/bg-form-contact.jpg") center center no-repeat;
        background-size: cover;
        h3 {
            font-size: 40px;
            font-family: $f-merri;
            margin-bottom: 70px;
        }
        p {
            margin-bottom: 35px;
            font-size: 18px;
            i {
                display: inline-block;
                vertical-align: middle;
                width: 41px;
                height: 41px;
                background-position: center center;
                margin-right: 5px;
                background-size: cover;
            }
        }
        a {
            color: #fff;
        }
        .socials {
            padding-top: 25px;
            li {
                display: inline-block;
                vertical-align: middle;
                margin-left: 15px;
            }
        }
        @include media-breakpoint-only(lg) {
            left: -100px;
        }

        @include media-breakpoint-only(md) {
            left: 0px;
        }

        @include media-breakpoint-only(sm) {
            left: 0px;
            width: 200px;
            padding: 30px 10px;
            h3 {
                font-size: 32px;
            }
            p {
                font-size: 14px;
                i {
                    width: 20px;
                    height: 20px;
                }
            }
            .socials {
                li {
                    margin-left: 5px;
                }
            }
        }

        @include media-breakpoint-down(xsm) {
            left: 0;
            right: 0;
            width: 100%;
            height: 160px;
            bottom: 0;
            top: auto;
            padding-top: 10px;
            h3 {
                font-size: 20px;
                margin-bottom: 10px;
            }
            p {
                display: inline-block;
                margin-right: 6px;
                font-size: 12px;
                margin-bottom: 5px;
                i {
                    width: 24px;
                    height: 24px;
                }
            }
            .socials {
                padding-top: 0;
            }
        }
    }
}

.i-marker {
    background-image: url("../images/i-marker.png");
}

.i-mail {
    background-image: url("../images/i-mail.png");
}

.i-phone {
    background-image: url("../images/i-phone.png");
}

//-------------------------------------------------------//
//------ HEADER
//-------------------------------------------------------//
@include media-breakpoint-down(sm) {
    #navbar-mobile {
        position: fixed;
        z-index: 500;
        background-color: rgba(47, 172, 223, 0.96);
        width: 100%;
        top: 0;
        left: 0;
        .navbar-nav {
            float: none;
            width: 100%;
            li {
                width: 100%;
                height: auto;
                line-height: auto;
                a {
                    font-weight: 500;
                    text-align: center;
                    display: block;
                    height: 100px;
                    line-height: 100px;
                    font-size: 21px;
                    color: #fff;
                    &:hover, &.active {
                        background-color: #299ed8;
                    }
                }
            }
        }
        .close-menu {
            cursor: pointer;
            position: relative;
            top: auto;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 110px !important;
            background-size: contain;
            background: transparent url("../images/bg-elipsoid-7.png") center top no-repeat;
            i {
                background: transparent url("../images/ico-menu-close.png") center top no-repeat;
                background-size: cover;
                display: inline-block;
                width: 34px;
                height: 34px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
            }
        }
    }
}


.header-container {}

.navbar {
    position: relative;
    min-height: 100px;
    .navbar-toggler {
        background-image: url("../images/ico-menu-collapse.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 36px;
        height: 24px;
        position: absolute;
        z-index: 9000;
        right: 15px;
        top: 0;
        bottom: 0;
        left: auto;
        margin: auto;
        display: none;
        &.collapsed, &.is-visible {
            display: block;
        }
    }
    @include media-breakpoint-down(sm) {
        // padding-left: 15px;
        // padding-right: 15px;
    }
}

.navbar-brand {
    display: inline-block;
    height: 100px;
    line-height: 100px;
    img {
        display: inline-block;
    }
}

.navbar-nav {
    li {
        display: inline-block;
        float: left;
        vertical-align: middle;
        height: 100px;
        line-height: 100px;
        @include media-breakpoint-up(md) {
            margin-left: 28px;
        }

        color: #000;
        &.active, &:hover {
            color: $c-blue;
            a {
                color: $c-blue;
            }
        }
        a {
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            font-weight: 700;
            line-height: 1.8;
            color: #000;
            line-height: inherit;
            &:hover {
                color: $c-blue;
            }
        }
    }
}

//-------------------------------------------------------//
//------ FOOTER
//-------------------------------------------------------//
footer {
    position: relative;
    padding-bottom: 20px;
    .navbar {
        position: relative;
        .hidden-md-up {
            li {
                display: inline-block;
                width: 49%;
                margin: 0 !important;
            }
        }
        .nav  li {
            line-height: 3em;
            max-height: 3em;
            @include media-breakpoint-down(sm) {
                line-height: 1.2em;
                height: auto;
                padding: 7px 0;
                a {
                    line-height: 1.2em;
                }
            }

            &:last-of-type {
                display: none !important;
            }
        }
        .navbar-brand {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .footer-contacts {
        font-size: 12px;
        color: #606060;
        float: right;
        position: relative;
        h6 {
            display: inline-block;
            span {
                font-weight: 700;
            }
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            overflow: hidden;
            padding: 30px 0 22px;
            margin-top: 20px;
            h6 {
                float: right;
                line-height: 1.5;
                @include media-breakpoint-down(xs) {
                    float: left;
                }
            }
        }
    }
    .footer-socials {
        padding-top: 15px;
        float: right;
        font-size: 26px;
        color: #606060;
        li {
            display: inline-block;
            vertical-align: middle;
            margin-left: 18px;
            img {
                display: block;
            }
        }
    }
}

.toTop {
    position: fixed;
    z-index: 200;
    bottom: 50px;
    display: none;
    right: 30px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.18);
    width: 60px;
    height: 60px;
    background: #fff url("../images/ico-arrow-up.png") center center no-repeat;
}

//-------------------------------------------------------//
//------ Page - Index
//-------------------------------------------------------//
@include media-breakpoint-up(md) {
    .pd-l-35-md-up {
        padding-left: 35px;
    }
    .pd-r-35-md-up {
        padding-right: 35px;
    }
}


h2.title {
    font-size: 48px;
    text-align: center;
    font-family: $f-merri;
    color: #000;
    font-weight: 700;
    font-style: italic;
}

h6.subtitle {
    color: #606060;
    font-size: 18px;
    max-width: 1000px;
    margin: auto;
    text-align: center;
}

.page-index {
    padding-top: 200px;
    background-repeat: no-repeat;
    background-position: center top;
    @include media-breakpoint-down(sm) {
        padding-top: 130px;
    }

    section.title {
        text-align: center;
        min-height: 900px;
        @include media-breakpoint-down(xs) {}

        h1 {
            font-family: $f-merri;
            font-size: 60px;
            color: #fff;
            margin: 0px;
            font-weight: 700;
            font-style: italic;
            text-align: center;
        }
        h6 {
            font-size: 18px;
            color: #fff;
            width: 100%;
            max-width: 820px;
            text-align: center;
            margin: 20px auto 40px;
            line-height: 28px;
        }
        .btn {
            margin: auto;
        }
    }
    section.solution {
        margin-top: -210px;
        @include media-breakpoint-down(sm) {
            margin-top: -140px;
        }

        h2 {
            margin: 0;
        }
        h6 {
            margin-top: 20px;
            margin-bottom: 25px;
        }
    }
    .wrapp-post {
        margin-top: 45px;
        font-size: 16px;
        color: #606060;
        font-style: italic;
        .title {
            margin-bottom: 0;
            font-size: 18px;
            color: #000;
            font-weight: 700;
            font-style: normal;
        }
        img {
            -webkit-box-shadow: 0px 0px 75px -21px rgba(85, 85, 85, 1);
            -moz-box-shadow: 0px 0px 75px -21px rgba(85, 85, 85, 1);
            box-shadow: 0px 0px 75px -21px rgba(85, 85, 85, 1);
            display: inline-block;
            width: 100%;
            margin: 18px 0 15px;
        }
    }
}

section.notify {
    background: #fff url("../images/bg-sec-notify.jpg") center top no-repeat;
    background-size: 1920px 673px;
    padding-top: 200px;
    padding-bottom: 230px;
    position: relative;
    h6 {
        padding-left: 25px;
        font-size: 18px;
        color: #606060;
        margin-bottom: 15px;
    }
    form {
        width: 100%;
        max-width: 630px;
        .form-group {
            width: 100%;
            height: 50px;
            position: relative;
        }
        input[type='email'] {
            height: 100%;
            width: 100%;
            background-color: #fff;
            padding-left: 25px;
            padding-right: 175px;
            font-size: 16px;
            color: #606060;
            @include media-breakpoint-down(sm) {
                padding-right: 115px;
            }
        }
        input[type='submit'] {
            position: absolute;
            left: auto;
            right: 0;
            top: 0;
            height: 100%;
            line-height: 100%;
            font-size: 16px;
            font-weight: 400;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            @include media-breakpoint-down(sm) {
                min-width: 100px;
            }
        }
    }
}

//-------------------------------------------------------//
//------ Page About_us
//-------------------------------------------------------//
.img-shadow {
    -webkit-box-shadow: 0px 0px 75px -21px rgba(85, 85, 85, 1);
    -moz-box-shadow: 0px 0px 75px -21px rgba(85, 85, 85, 1);
    box-shadow: 0px 0px 75px -21px rgba(85, 85, 85, 1);
}

.head-title {
    background: #fff url("../images/bg-elipsoid-1.png") center top no-repeat;
    padding: 70px 0 40px;
    min-height: 250px;
}

.page-about_us {
    .content {
        padding-bottom: 100px;
        img {
            margin: 60px 0 40px;
            @extend .img-shadow;
            display: block;
            width: 100%;
        }
        p {
            margin-top: 30px;
            margin-bottom: 0;
        }
    }
    .gallery {
        position: relative;
        background: #fff url("../images/bg-elipsoid-2.png") center top no-repeat;
        padding: 110px 0;
        @include media-breakpoint-down(sm) {
            padding: 50px 0;
        }

        img {
            width: 100%;
        }
    }
}

.owl-dots {
    text-align: center;
    .owl-dot {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin: 20px 6px;
        background-color: #dcdcdc;
        transition: all 0.4s ease-out;
        &.active {
            background-color: #2facdf;
            transition: all 0.4s ease-out;
        }
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

//-------------------------------------------------------//
//------ Page Steps
//-------------------------------------------------------//
.page-steps {
    position: relative;
    overflow: hidden;
    .bg-feets {
        top: -90px;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
        background: transparent url("../images/bg-feets-1.png") center 300px no-repeat;
    }
    h3 {
        font-weight: 700;
        font-size: 30px;
        color: #000;
    }
    .column-title {
        margin-top: -68px;
    }
    .step {
        position: relative;
        padding: 80px 0;
        &:nth-of-type(2n+1) {
            background: #fff url("../images/bg-elipsoid-4.png") center center no-repeat;
            background-size: 1920px 100%;
            // @include media-breakpoint-down(md) {
            //     background: #f5f5f5;
            // }
            padding: 100px 0;
            .step-number {
                margin-top: -16px;
            }
        }
        &:nth-of-type(3n+1) {
            .step-number {
                margin-top: -35px;
            }
        }
        .step-number {
            position: absolute;
            z-index: 100;
            width: 50px;
            height: 50px;
            background-color: #fff;
            color: #cf3a99;
            font-weight: 700;
            font-style: italic;
            font-family: $f-merri;
            text-align: center;
            line-height: 50px;
            font-size: 30px;
            border-radius: 50%;
            -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.18);
            -moz-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.18);
            box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.18);
            top: 0;
            bottom: auto;
            left: 0;
            right: 0;
            margin: auto;
        }
        .seller-post {
            width: 33%;
            display: inline-block;
            vertical-align: middle;
        }
        .wrapp-step-name {
            width: 34.5%;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            .step-name {
                z-index: 300;
                background: transparent url("../images/bg-elipsoid-3.png") center top no-repeat;
                position: relative;
                width: 260px;
                margin: auto;
                font-family: $f-merri;
                color: #000;
                font-size: 18px;
                text-align: center;
                font-weight: 700;
                font-style: italic;
                height: 140px;
                line-height: 125px;
                &:before {
                    content: '';
                    width: 6px;
                    height: 10px;
                    position: absolute;
                    top: 57px;
                    left: 40px;
                    right: auto;
                    bottom: auto;
                    margin: auto;
                    background-image: url("../images/ico-arrow-blue-left.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                &:after {
                    content: '';
                    width: 7px;
                    height: 10px;
                    position: absolute;
                    top: 57px;
                    left: auto;
                    right: 40px;
                    bottom: auto;
                    margin: auto;
                    background-image: url("../images/ico-arrow-pink-right.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
        }
        .buyer-post {
            width: 31%;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .final-step {
        background: transparent url("../images/bg-elipsoid-5.png") center top no-repeat;
        text-align: center;
        font-size: 30px;
        color: #000;
        padding: 70px 0 130px;
    }
    @include media-breakpoint-down(sm) {
        .final-step {
            margin-top: 40px;
            padding-bottom: 90px;
        }
        .column-title {
            position: relative;
            z-index: 200;
            margin-top: 20px;
            text-align: center;
            .column-title-seller, .column-title-buyer {
                display: inline-block;
                cursor: pointer;
                min-width: 120px;
                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 70px;
                    background: transparent url("../images/client-shape-black.png") center center no-repeat;
                }
                &.active {
                    &:before {
                        background: transparent url("../images/client-shape-color.png") center center no-repeat;
                    }
                    h3 {
                        color: #2facdf;
                    }
                }
                h3 {
                    font-size: 24px;
                    color: #606060;
                }
            }
        }
        .step {
            font-size: 24px;
            position: relative;
            &:nth-of-type(2n+2) {
                background: #fff url("../images/bg-elipsoid-4.png") center center no-repeat;
                background-size: 1920px 100%;
            }
            &:nth-of-type(2n+1) {
                background: none;
            }
            .seller-post, .buyer-post {
                display: none;
                width: 100% !important;
                &.active {
                    display: block;
                }
                p {
                    color: #000;
                }
            }
            .wrapp-step-name {
                position: absolute;
                top: 20px;
                left: 82px;
                width: auto;
                .step-name {
                    text-align: left;
                    font-family: 'Lato', sans-serif;
                    background: none;
                    height: 54px;
                    line-height: 54px;
                    font-size: 30px;
                    font-weight: 700;
                    &:after, &:before {
                        display: none;
                    }
                }
            }
            .step-number {
                top: 20px;
                right: auto;
                left: 12px;
                margin-top: 0 !important;
            }
        }
    }
}

//-------------------------------------------------------//
//------ Page Map
//-------------------------------------------------------//
.page-map {
    h2.title {
        margin-bottom: 70px;
    }
    position: relative;
    background: #fff url("../images/bg-map.jpg") 0 0 no-repeat;
    background-size: cover;
    padding: 70px 0;
    .toTop {
        bottom: -22px;
    }
    .footer-container {
        padding-top: 20px;
    }
    ul.items {
        position: relative;
        li {
            position: relative;
            background-color: #fff;
            @extend .img-shadow;
            padding: 50px 35px 60px;
            font-size: 20px;
            font-weight: 700;
            font-style: italic;
            font-family: $f-merri;
            color: $c-blue;
            text-align: center;
            width: 100%;
            max-width: 475px;
            margin-bottom: -40px;
            &:nth-of-type(1) {
                // left: auto;
                z-index: 100;
                margin-right: 0;
                margin-left: auto;
            }
            &:nth-of-type(2) {
                left: 45%;
                z-index: 99;
            }
            &:nth-of-type(3) {
                left: 30%;
                z-index: 98;
            }
            &:nth-of-type(4) {
                left: 15%;
                z-index: 97;
            }
            &:nth-of-type(5) {
                left: 0;
                right: auto;
                z-index: 96;
                margin-bottom: 0;
            }
            @include media-breakpoint-only(lg) {
                &:nth-of-type(2) {
                    left: 40%;
                }
                &:nth-of-type(3) {
                    left: 25%;
                }
                &:nth-of-type(4) {
                    left: 10%;
                }
            }

            @include media-breakpoint-only(md) {
                &:nth-of-type(2) {
                    left: 25%;
                }
                &:nth-of-type(3) {
                    left: 13%;
                }
                &:nth-of-type(4) {
                    left: 5%;
                }
            }

            @include media-breakpoint-down(sm) {
                left: auto !important;
                right: auto !important;
                margin: 20px auto !important;
            }
        }
    }
}

//-------------------------------------------------------//
//------ Page Testers
//-------------------------------------------------------//
.page-testers {
    form {
        overflow: hidden;
        margin-top: 65px;
        border: 2px solid #dfdfdf;
        width: 100%;
        padding: 0 45px;
        @include media-breakpoint-down(xsm) {
            padding: 0 10px;
        }

        .form-group {
            margin-bottom: 0;
            border-bottom: 1px solid #efefef;
            padding: 45px 0;
            @include media-breakpoint-down(sm) {
                padding: 15px 0;
            }

            input[type="submit"] {
                height: 48px;
                line-height: 48px;
                font-size: 16px;
            }
            > label {
                font-weight: 700;
                font-size: 20px;
                width: 210px;
                display: inline-block;
                vertical-align: middle;
                @include media-breakpoint-down(md) {
                    width: 170px;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            input[type='text'], input[type='email'] {
                border: 1px solid #dfdfdf;
                height: 48px;
                display: inline-block;
                vertical-align: middle;
                width: 500px;
                padding: 0 15px;
                border-radius: 2px;
                @include media-breakpoint-down(md) {
                    width: 300px;
                }

                @include media-breakpoint-down(xxs) {
                    width: 100%;
                }
            }
            &:nth-of-type(1) {
                input[type='text'] {
                    width: 245px;
                    @include media-breakpoint-down(md) {
                        width: 145px;
                    }

                    @include media-breakpoint-down(xxs) {
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
            &:nth-of-type(8) {
                > p {
                    display: none;
                    // this is rubish from wordpress
               
                }
                .input-group {
                    width: 515px;
                    display: inline-block;
                    vertical-align: middle;
                    @include media-breakpoint-down(md) {
                        width: 300px;
                    }

                    label {
                        border-radius: 2px;
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        width: 78px;
                        height: 48px;
                        line-height: 48px;
                        cursor: pointer;
                        border: 1px solid #dfdfdf;
                        text-align: center;
                        font-size: 14px;
                        &.checked {
                            border: 1px solid #cf3a99;
                            font-weight: 700;
                            color: #000;
                        }
                    }
                    input[type='radio'] {
                        position: absolute;
                        top: -9999px;
                        left: -9999px;
                        display: none;
                    }
                    // for wordpress
                    .age_of_baby {
                        > span {
                            margin-left: 0px;
                           
                        }
                    }
                }
            }
            &:nth-of-type(9) {
                .input-group {
                    width: 500px;
                    display: inline-block;
                    vertical-align: middle;
                    label {
                        padding-left: 48px;
                        border-radius: 2px;
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        height: 48px;
                        line-height: 48px;
                        width: auto;
                        cursor: pointer;
                        font-size: 16px;
                        margin-right: 30px;
                        &:after {
                            border: 1px solid #dfdfdf;
                            content: '';
                            position: absolute;
                            z-index: 100;
                            top: 0;
                            left: 0;
                            right: auto;
                            margin: auto;
                            bottom: 0;
                            width: 38px;
                            height: 38px;
                            background-color: #fff;
                            border-radius: 50%;
                        }
                        &:before {
                            content: '';
                            border-radius: 50%;
                            position: absolute;
                            z-index: 110;
                            top: 0px;
                            left: 6px;
                            right: auto;
                            margin: auto;
                            bottom: 0;
                            width: 28px;
                            height: 28px;
                            background-color: #cf3a99;
                            transition: all 0.3s ease-out;
                            transform: scale(0);
                        }
                        &.checked {
                            font-weight: 700;
                            color: #000;
                            &:after {
                                border: 1px solid #cf3a99;
                            }
                            &:before {
                                // top: 5px;
                                left: 5px;
                                transform: scale(1);
                                transition: all 0.3s ease-out;
                            }
                        }
                    }
                    input[type='radio'] {
                        position: absolute;
                        top: -9999px;
                        left: -9999px;
                        display: none;
                    }
                }
            }
            &:nth-of-type(10) {
                input {
                    width: 160px;
                    padding-right: 50px;
                }
                .how_many {
                    position: relative;
                    &:after {
                        content: "OZ";
                        left: 132px;
                        top: 3px;
                        font-size: 14px;
                        position: absolute;
                    }
                }
            }
            &:nth-of-type(12), &:nth-of-type(13) {
                border-bottom: 0;
            }
            &:nth-of-type(13) {
                padding-top: 0;
            }
        }
    }
    .foot-toTop {
        margin-top: 40px;
        position: relative;
        height: 65px;
        background: #fff url("../images/bg-elipsoid-6.png") center top no-repeat;
        background-size: cover;
    }
    // .wrapp-form {
    //     iframe {
    //         width: 100%;
    //     }
    //     form {
    //         margin-top: 65px;
    //         border: 2px solid #dfdfdf;
    //         width: 100%;
    //         padding: 0 45px 20px;
    //         @include media-breakpoint-down(xsm) {
    //             padding: 0 10px;
    //         }
    //         #containment {
    //             overflow: hidden;
    //         }
    //         button[type='reset'] {
    //             display: none;
    //         }
    //         button[type='submit'] {
    //             height: 48px;
    //             line-height: 48px;
    //             font-size: 16px;
    //             color: #fff;
    //             background-color: #2facdf;
    //             border-color: transparent;
    //             display: inline-block;
    //             min-width: 160px;
    //         }
    //         input[type='text'], input[type='email'] {
    //             border: 1px solid #dfdfdf;
    //             height: 48px;
    //             display: inline-block;
    //             vertical-align: middle;
    //             width: 500px;
    //             padding: 0 15px;
    //             border-radius: 2px;
    //             @include media-breakpoint-down(md) {
    //                 width: 300px;
    //             }
    //             @include media-breakpoint-down(xxs) {
    //                 width: 100%;
    //             }
    //         }
    //         .item {
    //             margin-bottom: 0;
    //             padding: 32px 0 22px;
    //             position: relative;
    //             &:after {
    //                 content: "";
    //                 position: absolute;
    //                 bottom: 0;
    //                 left: -1000px;
    //                 height: 0;
    //                 border-bottom: 1px solid #efefef;
    //                 width: 9000px;
    //             }
    //             &:last-of-type, &:nth-of-type(8) {
    //                 padding-bottom: 20px;
    //                 &:after {
    //                     display: none;
    //                 }
    //             }
    //             &:last-of-type {
    //                 padding-top: 0;
    //             }
    //             @include media-breakpoint-down(sm) {
    //                 padding: 15px 0;
    //             }
    //             div.form-group {
    //                 overflow: hidden;
    //                 margin-bottom: 0;
    //                 > label {
    //                     margin-bottom: 0;
    //                     @include media-breakpoint-down(sm) {
    //                         height: 36px;
    //                     }
    //                 }
    //             }
    //             &:nth-of-type(1) {
    //                 display: inline-block;
    //                 width: auto;
    //                 .wrap {
    //                     display: inline-flex;
    //                     align-items: center;
    //                     width: 250px;
    //                     @include media-breakpoint-only(md) {
    //                         width: 148px;
    //                     }
    //                 }
    //                 input {
    //                     width: 245px;
    //                     @include media-breakpoint-only(md) {
    //                         width: 145px;
    //                     }
    //                     @include media-breakpoint-down(xxs) {
    //                         width: 100%;
    //                         margin-top: 10px;
    //                     }
    //                 }
    //                 @include media-breakpoint-down(sm) {
    //                     display: block;
    //                     width: 100%;
    //                     .wrap {
    //                         width: 300px;
    //                         .form-wrap {
    //                             width: 100%;
    //                         }
    //                         input {
    //                             width: 100%;
    //                         }
    //                     }
    //                 }
    //             }
    //             &:nth-of-type(2) {
    //                 display: inline-block;
    //                 width: auto;
    //                 vertical-align: top;
    //                 .form-group {
    //                     > label {
    //                         display: none;
    //                     }
    //                 }
    //                 input {
    //                     width: 245px;
    //                     @include media-breakpoint-only(md) {
    //                         width: 145px;
    //                     }
    //                     @include media-breakpoint-down(xxs) {
    //                         width: 100%;
    //                         margin-top: 10px;
    //                     }
    //                 }
    //                 @include media-breakpoint-down(sm) {
    //                     display: block;
    //                     width: 100%;
    //                     .form-group {
    //                         > label {
    //                             display: inline-flex;
    //                         }
    //                     }
    //                     .wrap {
    //                         width: 300px;
    //                         .form-wrap {
    //                             width: 100%;
    //                         }
    //                         input {
    //                             width: 100%;
    //                         }
    //                     }
    //                 }
    //             }
    //             &:nth-of-type(3) {}
    //             &:nth-of-type(4) {
    //                 .wrap {
    //                     @include media-breakpoint-down(md) {
    //                         width: 330px;
    //                     }
    //                     .col {
    //                         width: auto;
    //                         padding: 0;
    //                         .radio {
    //                             float: left;
    //                             margin-right: 6px;
    //                             @include media-breakpoint-down(md) {
    //                                 float: none;
    //                             }
    //                         }
    //                     }
    //                     label {
    //                         border-radius: 2px;
    //                         position: relative;
    //                         display: inline-block;
    //                         vertical-align: middle;
    //                         width: 78px;
    //                         height: 48px;
    //                         line-height: 48px;
    //                         cursor: pointer;
    //                         border: 1px solid #dfdfdf;
    //                         text-align: center;
    //                         font-size: 14px;
    //                         &.active {
    //                             border: 2px solid #cf3a99;
    //                             height: 46px;
    //                             width: 76px;
    //                             line-height: 44px;
    //                             font-weight: 700;
    //                             color: #000;
    //                         }
    //                     }
    //                     input[type='radio'] {
    //                         position: absolute;
    //                         top: -9999px;
    //                         left: -9999px;
    //                         display: none;
    //                     }
    //                 }
    //             }
    //             &:nth-of-type(5) {
    //                 .col {
    //                     width: 140px;
    //                 }
    //                 .wrap {
    //                     label {
    //                         padding-left: 48px;
    //                         border-radius: 2px;
    //                         position: relative;
    //                         display: inline-block;
    //                         vertical-align: middle;
    //                         height: 48px;
    //                         line-height: 48px;
    //                         width: auto;
    //                         cursor: pointer;
    //                         font-size: 16px;
    //                         margin-right: 30px;
    //                         &.active {
    //                             font-weight: 700;
    //                             color: #000;
    //                             &:after {
    //                                 border: 1px solid #cf3a99;
    //                             }
    //                             &:before {
    //                                 // top: 5px;
    //                                 left: 5px;
    //                                 transform: scale(1);
    //                                 transition: all 0.3s ease-out;
    //                             }
    //                         }
    //                         &:after {
    //                             border: 1px solid #dfdfdf;
    //                             content: '';
    //                             position: absolute;
    //                             z-index: 100;
    //                             top: 0;
    //                             left: 0;
    //                             right: auto;
    //                             margin: auto;
    //                             bottom: 0;
    //                             width: 38px;
    //                             height: 38px;
    //                             background-color: #fff;
    //                             border-radius: 50%;
    //                         }
    //                         &:before {
    //                             content: '';
    //                             border-radius: 50%;
    //                             position: absolute;
    //                             z-index: 110;
    //                             top: 0px;
    //                             left: 6px;
    //                             right: auto;
    //                             margin: auto;
    //                             bottom: 0;
    //                             width: 28px;
    //                             height: 28px;
    //                             background-color: #cf3a99;
    //                             transition: all 0.3s ease-out;
    //                             transform: scale(0);
    //                         }
    //                     }
    //                     input[type='radio'] {
    //                         position: absolute;
    //                         top: -9999px;
    //                         left: -9999px;
    //                         display: none;
    //                     }
    //                 }
    //             }
    //             &:nth-of-type(6) {
    //                 .wrap {
    //                     display: inline-flex;
    //                     align-items: center;
    //                     position: relative;
    //                     width: 160px;
    //                 }
    //                 input {
    //                     width: 160px;
    //                     padding-right: 50px;
    //                     @include media-breakpoint-down(md) {
    //                         width: 145px;
    //                     }
    //                     @include media-breakpoint-down(xxs) {
    //                         width: 100%;
    //                         margin-top: 10px;
    //                     }
    //                 }
    //                 .form-wrap:after {
    //                     content: "OZ";
    //                     position: absolute;
    //                     top: 0;
    //                     bottom: 0;
    //                     margin: auto;
    //                     right: 20px;
    //                     line-height: 1.2;
    //                     height: 1.6em;
    //                 }
    //             }
    //             &:last-of-type {
    //                 .form-group {
    //                     > label {
    //                         @include media-breakpoint-down(sm) {
    //                             display: none;
    //                         }
    //                     }
    //                 }
    //             }
    //             .form-group {
    //                 > label {
    //                     font-weight: 700;
    //                     font-size: 20px;
    //                     width: 210px;
    //                     display: inline-block;
    //                     vertical-align: middle;
    //                     padding: 0;
    //                     height: 64px;
    //                     line-height: 1.2;
    //                     display: inline-flex;
    //                     align-items: center;
    //                     @include media-breakpoint-down(md) {
    //                         width: 170px;
    //                     }
    //                     @include media-breakpoint-down(sm) {
    //                         width: 100%;
    //                     }
    //                 }
    //                 .wrap {
    //                     padding: 0;
    //                     .form-wrap {
    //                         padding-top: 10px;
    //                     }
    //                 }
    //             }
    //         }
    //         @include media-breakpoint-down(xsm) {
    //             .item {
    //                 .wrap {
    //                     width: 100% !important;
    //                     input[type='text'], input[type='email'] {
    //                         width: 100%;
    //                     }
    //                 }
    //                 &:nth-of-type(6) {
    //                     .form-wrap {
    //                         width: 100% !important;
    //                     }
    //                 }
    //             }
    //             .col {
    //                 float: left;
    //             }
    //         }
    //     }
    //     // /form
    // }
}

//-------------------------------------------------------//
//------ Page Blog
//-------------------------------------------------------//

.page-blog {
    background: #fff url("../images/bg-blog.jpg") center top no-repeat;
    background-size: cover;
    padding-bottom: 40px;
    .posts {
        .item-post {
            margin-bottom: 50px;
            position: relative;
            color: #fff;
            float: left;
            background-position: center top;
            background-size: cover;
            @include media-breakpoint-up(md) {
                background-image: none !important;
            }

            @include media-breakpoint-down(sm) {
                img {
                    display: none !important;
                }
            }

            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;
            }

            &:nth-of-type(1) {
                .text h3 {
                    font-size: 36px;
                    margin: 52px 0 15px;
                }
                @include media-breakpoint-only(lg) {
                    .text {
                        max-width: 900px;
                        h3 {
                            margin-top: 40px;
                        }
                    }
                }

                @include media-breakpoint-only(md) {
                    .text {
                        max-width: 700px;
                        h3 {
                            margin-top: 15px;
                        }
                        .btn {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
            &:nth-of-type(n+2) {
                @include media-breakpoint-only(lg) {
                    .text {
                        h3 {
                            font-size: 20px;
                        }
                        .btn {
                            left: 30px;
                        }
                    }
                }

                @include media-breakpoint-only(md) {
                    .text {
                        max-width: 700px;
                        h3 {
                            font-size: 20px;
                        }
                        .btn {
                            left: 30px;
                        }
                    }
                }
            }
            &:nth-of-type(2) {
                width: 50%;
                padding-right: 25px;
                @include media-breakpoint-only(lg) {
                    padding-right: 15px;
                }

                @include media-breakpoint-only(md) {
                    padding-right: 15px;
                }
            }
            &:nth-of-type(3) {
                width: 50%;
                padding-left: 25px;
                .text {
                    padding-left: 55px;
                    .btn {
                        left: 50px;
                    }
                }
                @include media-breakpoint-only(lg) {
                    padding-left: 15px;
                    .text {}
                }

                @include media-breakpoint-only(md) {
                    padding-left: 15px;
                    .text {
                        .btn {
                            left: 45px;
                        }
                    }
                }
            }
            &:nth-of-type(3n+4) {
                width: 33%;
                padding-right: 33.5px;
                .text {
                    .btn {
                        left: 100px;
                    }
                }
                @include media-breakpoint-only(lg) {
                    padding-right: 20px;
                    .text {
                        .btn {
                            left: 30px;
                        }
                    }
                }

                @include media-breakpoint-only(md) {
                    padding-right: 20px;
                    .text {
                        .btn {
                            left: 30px;
                        }
                    }
                }
            }
            &:nth-of-type(3n+5) {
                width: 33%;
                padding: 0 16.5px;
                .text {
                    text-align: center;
                    .btn {
                        left: 114px;
                    }
                }
                @include media-breakpoint-only(lg) {
                    padding: 0 10px;
                    .text {
                        .btn {
                            left: 30px;
                        }
                    }
                }

                @include media-breakpoint-only(md) {
                    padding: 0 10px;
                    .text {
                        .btn {
                            left: 30px;
                        }
                    }
                }
            }
            &:nth-of-type(3n+6) {
                width: 33%;
                padding-left: 33.5px;
                .text {
                    text-align: center;
                    .btn {
                        left: 130px;
                    }
                }
                @include media-breakpoint-only(lg) {
                    padding-left: 20px;
                    .text {
                        .btn {
                            left: 40px;
                        }
                    }
                }

                @include media-breakpoint-only(md) {
                    padding-left: 20px;
                    .text {
                        .btn {
                            left: 40px;
                        }
                    }
                }
            }
            img {
                display: block;
                width: 100%;
            }
            .text {
                position: absolute;
                z-index: 100;
                top: 0;
                width: 100%;
                max-width: 440px;
                height: 100%;
                left: 0;
                padding: 20px 30px 70px;
                h3 {
                    font-size: 24px;
                    line-height: 1.3;
                    font-family: $f-merri;
                    font-weight: 700;
                    font-style: italic;
                }
                p {
                    font-weight: 700;
                }
                .btn {
                    position: absolute;
                    left: 30px;
                    bottom: 20px;
                    min-width: 180px;
                }
            }
            @include media-breakpoint-down(sm) {
                min-height: 300px;
                width: 100% !important;
                img {
                    display: none;
                }
                .text {
                    min-height: 300px;
                    position: relative;
                    h3 {
                        margin-top: 15px;
                    }
                    .btn {
                        left: 30px;
                    }
                }
            }

            @include media-breakpoint-down(xsm) {
                padding-left: 0px !important;
                .text {
                    padding: 10px 15px 60px !important;
                    h3 {
                        margin-top: 15px;
                    }
                    .btn {
                        left: 15px !important;
                    }
                }
            }
        }
    }
    .item-post-carousel {
        height: 250px;
        width: 100%;
        background-size: cover;
        color: #fff;
        text-align: center;
        .text {
            height: 250px;
            h3 {
                padding: 20px 10px;
                height: 170px;
            }
        }
    }
    .wrapp-toTop {
        position: relative;
        .toTop {
            bottom: -15px;
        }
    }
    .owl-carousel {
        max-width: 1200px;
        margin: auto;
    }
}